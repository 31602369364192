import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from './../models/breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public breadcrumbLabels: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public newBreadcrumb: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

  constructor() {}

  /**
   * Updates the breadcrumb labels with the provided values.
   *
   * @param {any} labels - The new labels to set for the breadcrumb.
   * @return {void} This function does not return anything.
   */
  updateBreadcrumbLabels(labels: any) {
    this.breadcrumbLabels.next(labels);
  }

  /**
   * Updates the breadcrumb with the provided new breadcrumb.
   *
   * @param {Breadcrumb[]} newBreadcrumb - The new breadcrumb to update the breadcrumb with.
   * @return {void} This function does not return anything.
   */
  updateBreadcrumb(newBreadcrumb: Breadcrumb[]) {
    this.newBreadcrumb.next(newBreadcrumb);
  }
}
