import { Component, Inject, Injector, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { COMMON_MODULES } from '@app/core/constants';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@src/app/core/base';
// import { AuthService } from '@src/app/core/services';
import { BreadcrumbComponent } from '@src/app/core/shared/components/breadcrumb/breadcrumb.component';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { FeatherIconModule } from '@src/app/core/shared/directives/feather-icon/feather-icon.module';
import { Store } from '@ngrx/store';
import { AuthActions, selectUser } from '@src/app/features/auth/core/store';

@Component({
  selector: 'app-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  imports: [...COMMON_MODULES, FeatherIconModule, NgbDropdownModule, BreadcrumbComponent],
})
export class NavbarComponent extends BaseComponent implements OnInit, OnDestroy {
  private breadcrumbsSubject: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$: Observable<Breadcrumb[]> = this.breadcrumbsSubject.asObservable();

  public selectedLanguage: string = 'English';
  public selectedIntercity: boolean = false;
  private _onDestroy$: Subject<void> = new Subject<void>();
  @Input() pageSetting: any = null;
  user$ = this.store.select(selectUser);
  /**
   * Executes when the component is destroyed.
   * Calls the `ngOnDestroy` method of the superclass.
   */
  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  breadcrumbs: Breadcrumb[] = [];

  /**
   * Initializes a new instance of the class.
   *
   * @param {Document} document - The document object.
   * @param {Injector} injector - The injector object.
   * @param {Renderer2} renderer - The renderer object.
   * @param {Title} titleService - The title service object.
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    injector: Injector,
    private renderer: Renderer2,
    private titleService: Title,
    private store: Store,
  ) {
    super(injector);
    this.authService.userSubject.pipe(takeUntil(this._onDestroy$)).subscribe((user: any) => {
      this.currentUser = user;
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      });
    const storedBreadcrumbs = this.getStoredBreadcrumbs();
    if (storedBreadcrumbs.length > 0) {
      this.breadcrumbs = storedBreadcrumbs;
    } else {
      this.updateBreadcrumbs(); // Update with initial breadcrumbs
    }

    const sidAppConfig = JSON.parse(localStorage?.getItem('sid-app-config') || '{}');
    this.selectedLanguage = sidAppConfig?.language === 'fr' ? 'French' : 'English';

    // const sidAppConfig = localStorage?.getItem('sid-app-config');
    // let lang = 'fr';
    // if (sidAppConfig) {
    //   lang = JSON?.parse(sidAppConfig)?.language || 'fr';
    // }
    // this.selectedLanguage = lang === 'fr' ? 'French' : 'English';

    const intercity = localStorage?.getItem('intercity') === 'true';
    this.selectedIntercity = intercity;

    // const intercity: any = localStorage.getItem('intercity');
    // if (intercity == 'true' || intercity == true) {
    //   this.selectedIntercity = true;
    // } else {
    //   this.selectedIntercity = false;
    // }
    this.sharedDataService.selectIntercity = this.selectedIntercity;
  }
  ngOnInit() {}

  /**
   * Updates the breadcrumbs data and stores it in the local storage.
   *
   * @param {Breadcrumb[]} breadcrumbs - The array of breadcrumb objects.
   * @return {void} This function does not return anything.
   */
  updateBreadcrumbsData(breadcrumbs: Breadcrumb[]): void {
    this.breadcrumbsSubject.next(breadcrumbs);
    localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
  }

  /**
   * Retrieves the stored breadcrumbs from local storage and returns them as an array of Breadcrumb objects.
   *
   * @return {Breadcrumb[]} An array of Breadcrumb objects representing the stored breadcrumbs. If no breadcrumbs are stored, an empty array is returned.
   */
  getStoredBreadcrumbs(): Breadcrumb[] {
    const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
    return storedBreadcrumbs ? JSON.parse(storedBreadcrumbs) : [];
  }

  /**
   * Updates the selected language and sets it in the local storage.
   *
   * @param {string} language - The selected language.
   * @return {void} This function does not return anything.
   */
  changeLanguage(language: string): void {
    this.selectedLanguage = language;
    const ln = language === 'English' ? 'en' : 'fr';
    this.localStorageService.setDataInLocalStorage({ key: 'language', value: { code: ln } }, false);
    this.sharedDataService.setUserLanguage(ln);
    this.sharedDataService.setAppConfig();
    this.sharedDataService.currentLanguage = ln;
    this.translate.get('PAGE_TITLE').subscribe(name => {
      this.titleService.setTitle(name);
    });
  }
  /**
   * Updates the selected intercity and updates the corresponding data in local storage,
   * shared data service, and intercity settings service.
   *
   * @param {boolean} intercity - The new selected intercity value.
   * @return {void} This function does not return anything.
   */
  changeIntercity(intercity: boolean): void {
    this.selectedIntercity = intercity;
    const int = intercity ? true : false;
    const inter = int;
    this.localStorageService.setDataInLocalStorage({ key: 'intercity', value: inter }, false);
    this.sharedDataService.setUserLanguage(int);
    this.sharedDataService.setAppConfig();
    this.sharedDataService.selectIntercity = int;
  }

  /**
   * Formats a given string by splitting it into words, capitalizing the first letter of each word,
   * and joining the words back together with spaces.
   *
   * @param {string} str - The string to be formatted.
   * @return {string} The formatted string. If the input is not a non-empty string, an empty string is returned.
   */
  formatString(str: string) {
    return (
      (typeof str === 'string' &&
        str &&
        str
          ?.split('-') // split the string into words
          ?.map(word => ' ' + word?.charAt(0)?.toUpperCase() + word?.slice(1)) // capitalize the first letter of each word
          ?.join(' ')) ||
      ''
    ); // join the words back together with spaces
  }
  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  async onLogout(e: Event) {
    //preventing the default behavior of the click
    e.preventDefault();
    this.authService.logout();
  }

  createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      let label = child.snapshot.data['breadcrumb'] || routeURL;
      const breadcrumb: Breadcrumb = { label, url };

      if (label !== '' && url !== '') {
        const breadcrumb: Breadcrumb = { label, url };
        breadcrumbs.push(breadcrumb);
      }

      // Removed return statement here to allow iteration over all children
      this.createBreadcrumbs(child, url, breadcrumbs);
    }
    this.updateBreadcrumbs(breadcrumbs);
    return breadcrumbs;
  }

  /**
   * Updates the breadcrumbs data and stores it in the local storage.
   *
   * @param {Breadcrumb[]} breadcrumbs - The array of breadcrumb objects.
   * @return {void} This function does not return anything.
   */
  private updateBreadcrumbs(breadcrumbs: Breadcrumb[] = []): void {
    this.updateBreadcrumbsData(breadcrumbs);
  }
}

export interface Breadcrumb {
  label: any;
  url: any;
}
