<footer
  class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
  <p class="text-muted mb-1 mb-md-0">
    Copyright © 2025
    <a href="https://www.sid.com" target="_blank">SID</a>
    .
  </p>
  <p class="text-muted">
    Handcrafted With
    <i class="feather icon-heart mb-1 text-primary ms-1 icon-sm"></i>
  </p>
</footer>
