import { Component, OnInit, Input } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
  RoutesRecognized,
  RouterModule,
} from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Breadcrumb } from './../../../models/breadcrumb.model';
import { BreadcrumbService } from './../../../services/breadcrumb.service';
import { CommonModule } from '@angular/common';
import {HelperService} from '@src/app/core/services/helper.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
  imports: [RouterModule, CommonModule],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumb: Breadcrumb[] = [];
  @Input() bgColor = '#eee';
  @Input() fontSize = '18px';
  @Input() fontColor = '#0275d8';
  @Input() lastLinkColor = '#000';
  @Input() symbol = ' / ';
  params: any;

  /**
   * Constructs a new instance of the class.
   *
   * @param {ActivatedRoute} activatedRoute - The activated route.
   * @param {Router} router - The router.
   * @param {BreadcrumbService} BreadcrumbService - The breadcrumb service.
   * @param {HelperService} helperService - The helper service.
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private BreadcrumbService: BreadcrumbService,
    private helperService: HelperService,
  ) {
    this.breadCrumbData();
  }

  /**
   * Initializes the component and subscribes to the breadcrumbLabels and newBreadcrumb observables of the BreadcrumbService.
   * When the breadcrumbLabels observable emits a new value, it iterates over the labelData object and updates the labels of the breadcrumb items.
   * If a labelParam matches the label, it replaces the corresponding placeholder in the label with the dynamicData.
   * When the newBreadcrumb observable emits a new value, it calls the updateData method with the activatedRoute and breadcrumb parameters.
   *
   * @return {void}
   */
  ngOnInit() {
    this.BreadcrumbService.breadcrumbLabels.subscribe((labelData: any) => {
      for (const label in labelData) {
        if (labelData.hasOwnProperty(label)) {
          this.breadcrumb.map(crumb => {
            const labelParams = crumb.label.match(/[^{{]+(?=\}})/g);
            if (labelParams) {
              for (const labelParam of labelParams) {
                const dynamicData = labelData[label];
                if (labelParam === label) {
                  crumb.label = crumb.label.replace('{{' + labelParam + '}}', dynamicData);
                }
              }
            }
          });
        }
      }
    });

    this.BreadcrumbService.newBreadcrumb.subscribe((breadcrumb: Breadcrumb[]) => {
      if (breadcrumb.length > 0) {
        this.updateData(this.activatedRoute, breadcrumb);
      }
    });
  }

  /**
   * Retrieves the breadcrumb data based on the current navigation end event.
   *
   * This function subscribes to the router events and filters for NavigationEnd events.
   * It then maps the event to the activated route, navigates to the last child route,
   * and filters for the primary outlet. Finally, it subscribes to the route and updates
   * the breadcrumb data with the snapshot parameters.
   *
   * @return {void} This function does not return a value.
   */
  breadCrumbData(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        this.params = route.snapshot.params;
        this.updateData(route, null);
      });
  }

  /**
   * Updates the breadcrumb data based on the given route and new breadcrumb.
   *
   * @param {ActivatedRoute} route - The activated route.
   * @param {Breadcrumb[] | null} newBreadcrumb - The new breadcrumb data, or null if no new data is provided.
   * @return {void} This function does not return a value.
   */
  private updateData(route: ActivatedRoute, newBreadcrumb: Breadcrumb[] | null): void {
    if (route.snapshot.data['breadcrumb'] || newBreadcrumb) {
      const data = route.snapshot.data['breadcrumb']
        ? route.snapshot.data['breadcrumb']
        : newBreadcrumb;
      const breadcrumb = this.helperService.clone(data);
      breadcrumb.map((crumb: { url: string; label: string }) => {
        const urlChunks = crumb.url.split('/');
        for (const chunk of urlChunks) {
          if (chunk.includes(':')) {
            const paramID = chunk.replace(':', '');
            // const routerParamID = route.snapshot.params[paramID];
            const routerParamID = this.params[paramID];
            crumb.url = crumb.url.replace(`:${paramID}`, routerParamID);
          }
        }

        const labelParams = crumb.label.match(/[^{{]+(?=\}})/g);
        if (labelParams) {
          for (const labelParam of labelParams) {
            // const routerParamID = route.snapshot.params[labelParam.trim()];
            const routerParamID = this.params[labelParam.trim()];
            if (routerParamID) {
              crumb.label = crumb.label.replace('{{' + labelParam + '}}', routerParamID);
            } else {
              // crumb.label = crumb.label.replace('{{' + labelParam + '}}', '');
            }
          }
        }
      });
      this.breadcrumb = breadcrumb;
    } else {
      this.breadcrumb = [];
    }
  }
}
