import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import MetisMenu from 'metismenujs';

import { MenuItem } from '../../../interfaces/menu.interface';
import { Router, NavigationEnd } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { COMMON_MODULES, CURRENT_USER_DATA, ICONSAX } from '@app/core/constants';
import { SharedDataService } from '@src/app/core/services/shared-data.service';
import { ConfirmationDialogService } from '@src/app/core/services/confirmation-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatherIconModule } from '../../directives/feather-icon/feather-icon.module';
import { select, Store } from '@ngrx/store';
import { selectUser, } from '@src/app/features/auth/core/store/selectors/user.selectors';
import { firstValueFrom } from 'rxjs';
import { any } from 'cypress/types/bluebird';
import { User } from '@src/app/core/models';
import { LocalStorageService } from '@src/app/core/services';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [...COMMON_MODULES, FeatherIconModule, NgbDropdownModule],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  ICONSAX = ICONSAX;
  @ViewChild('sidebarToggler') sidebarToggler: ElementRef | any;

  menuItems: MenuItem[] = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef | any;
  dataModified: any;

  public userData: any;
  /**
   * Initializes a new instance of the SidebarComponent class.
   *
   * @param {Document} document - The document object.
   * @param {Renderer2} renderer - The renderer object.
   * @param {SharedDataService} sharedDataService - The shared data service object.
   * @param {Router} router - The router object.
   * @param {ConfirmationDialogService} confirmationDialogService - The confirmation dialog service object.
   * @param {TranslateService} translate - The translate service object.
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private sharedDataService: SharedDataService,
    private router: Router,
    public confirmationDialogService: ConfirmationDialogService,
    public translate: TranslateService,
    public localService: LocalStorageService,
  ) {
    this.userData = this.localService.getEncryptedData(CURRENT_USER_DATA);
    router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }
      }
    });
    this.sharedDataService.dataModified$.subscribe((res: any) => {
      this.dataModified = res;
    });
  }

  /**
   * Initializes the component and sets the menu items based on the shared data service.
   * Also sets up a media query listener for the desktop medium breakpoint (min-width:992px and max-width: 1199px)
   * and calls the `iconSidebar` method when the breakpoint changes.
   *
   * @return {void}
   */
  ngOnInit(): void {
    this.menuItems = this.sharedDataService.getMenu();

    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addEventListener('change', () => {
      this.iconSidebar;
    });
    this.iconSidebar(desktopMedium);
  }

  /**
   * Initializes the component after the view has been initialized. Activates the menu item and calls the `_activateMenuDropdown` method.
   *
   * @return {void}
   */
  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);
    this._activateMenuDropdown();
  }

  /**
   * Navigates to a specified link if the data has been modified and the user confirms the action.
   * Otherwise, navigates to the specified link directly.
   *
   * @param {MenuItem} item - The menu item containing the link to navigate to.
   * @return {Promise<void>} - A promise that resolves when the navigation is complete.
   */
  async goToLink(item: MenuItem) {
    if (this.dataModified?.isModified) {
      if (await this.openDialog()) {
        this.sharedDataService.dataModified.next({ isModified: false });
        this.router.navigate([item.link]);
      }
    } else if (item.isEnabled == false) {
      return
    }  else if (item.link) {
      this.router.navigateByUrl(item.link);
    }
  }

/**
 * Opens a confirmation dialog with a warning icon, a message indicating unsaved changes, and buttons for confirming or canceling the action.
 *
 * @return {Promise<boolean>} A promise that resolves to true if the user confirms the action, and false otherwise.
 */
 openDialog() {
    return this.confirmationDialogService.confirm({
      extraIcon: 'icon-warning-2',
      message: this.translate.instant('MSGS.GENERAL.UNSAVED_CHANGES'),
      btnOkText: this.translate.instant('GENERAL.BUTTONS.LEAVE_PAGE'),
      btnCancelText: this.translate.instant('GENERAL.BUTTONS.CANCEL'),
    });
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e: Event) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }

  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }

  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add('open-sidebar-folded');
    }
  }

  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove('open-sidebar-folded');
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(mq: MediaQueryList) {
    if (mq.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }

  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event: any) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.checked ? 'sidebar-dark' : 'sidebar-light');
    this.document.body.classList.remove('settings-open');
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }

  /**
   * Resets the menus
   */
  resetMenuItems() {
    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El?.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  }

  /**
   * Toggles the menu items
   */
  activateMenuItems() {
    const links: any = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      var subDirectory = window.location.pathname.split("/")[1]
      if (subDirectory === (links[i]['id'])) {
        menuItemEl = links[i];
        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  }
}
