<nav class="navbar">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="icon icon-bars"></i>
  </a>
  <!-- <nav class="page-breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink=".">Test</a></li>
      <li class="breadcrumb-item"><a routerLink=".">Test Elements</a></li>
      <li class="breadcrumb-item active" aria-current="page">Test Input Mask</li>
    </ol>
  </nav> -->

  <!-- <ul class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item">
      <a routerLink="{{ breadcrumb?.url }}">
        {{ breadcrumb?.label }}
      </a>
    </li>
  </ul> -->
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <div class="navbar-content align-items-center">
    <ul class="list-unstyled p-0 mb-0 breadcrumb">
      <!--   <li *ngFor="let breadcrumb of breadcrumbs; let last = last; let first = first">
        @if (breadcrumb.label) {
          @if (first) {
            <a class="active" [routerLink]="[breadcrumb.url]">
              {{ formatString(breadcrumb?.label) }}
            </a>
          } @else {
            <a [routerLink]="[breadcrumb.url]">{{ formatString(breadcrumb?.label) }}</a>
          }
        }
      </li> -->

      <li class="active" *ngIf="pageSetting?.link1">
        <a routerLink="{{ pageSetting?.link1?.link }}">
          {{ pageSetting?.link1?.title | translate }}
        </a>
      </li>
      <li *ngIf="pageSetting?.link1?.name">
        <a routerLink="{{ pageSetting?.link1?.link }}">
          {{ pageSetting?.link1?.name | translate }}
        </a>
      </li>
      <li *ngIf="pageSetting?.link2">
        @if (pageSetting?.link2?.link) {
          <a routerLink="{{ pageSetting?.link2?.link }}">
            {{ pageSetting?.link2?.title | translate }}
          </a>
        } @else {
          <a>
            {{ pageSetting?.link2?.title | translate }}
          </a>
        }
      </li>
    </ul>
    <ul class="navbar-nav">
      <!-- <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="languageDropdown" role="button">
          @if (selectedLanguage == 'English') {
            <img src="assets/images/flags/uk.svg" class="wd-20 me-1" title="uk" alt="uk" />
          } @else {
            <img src="assets/images/flags/fr.svg" class="wd-20 me-1" title="fr" alt="fr" />
          }
        </a>
        <div ngbDropdownMenu aria-labelledby="languageDropdown">
          <a ngbDropdownItem class="py-2" (click)="changeLanguage('English')">
            <img src="assets/images/flags/uk.svg" class="wd-20 me-1" title="uk" alt="uk" />
            <span class="ms-1">English</span>
          </a>
          <a ngbDropdownItem class="py-2" (click)="changeLanguage('French')">
            <img src="assets/images/flags/fr.svg" class="wd-20 me-1" title="fr" alt="fr" />
            <span class="ms-1">French</span>
          </a>
        </div>
      </li> -->

      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <img
            class="wd-30 ht-30 rounded-circle"
            [src]="
              (user$ | async)?.profilePictureDocument?.path ||
              './assets/images/user-profile-placeholder.png'
            "
            alt="user-profile-picture" />
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
          <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
            <div class="mb-3">
              <img
                class="wd-80 ht-80 rounded-circle"
                [src]="
                  (user$ | async)?.profilePictureDocument?.path ||
                  './assets/images/user-profile-placeholder.png'
                "
                alt="user-picture" />
            </div>
            <div class="text-center">
              <p class="tx-16 fw-bolder">
                {{ (user$ | async)?.firstName + ' ' + (user$ | async)?.lastName }}
              </p>
              <p class="tx-12 text-muted">{{ (user$ | async)?.email }}</p>
            </div>
          </div>
          <ul class="list-unstyled p-1">
            <!-- <li class="dropdown-item py-2">
              <a routerLink="/general/profile" class="d-flex text-body ms-0">
                <i class="feather icon-user me-2 icon-md"></i>
                <span>Profile</span>
              </a>
            </li>
            <li class="dropdown-item py-2">
              <a href="" (click)="(false)" class="d-flex text-body ms-0">
                <i class="feather icon-edit me-2 icon-md"></i>
                <span>Edit Profile</span>
              </a>
            </li>
            <li class="dropdown-item py-2">
              <a href="" (click)="(false)" class="d-flex text-body ms-0">
                <i class="feather icon-repeat me-2 icon-md"></i>
                <span>Switch User</span>
              </a>
            </li> -->
            <li class="dropdown-item py-2">
              <a href="" class="d-flex text-body ms-0" routerLink="/profile">
                <i class="isax isax-user d-flex align-items-center me-2"></i>
                <span>Profile</span>
              </a>
            </li>

            <li class="dropdown-item py-2" (click)="onLogout($event)">
              <a href="" class="d-flex text-body ms-0">
                <i class="isax isax-logout d-flex align-items-center me-2"></i>
                <span>Log Out</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>
