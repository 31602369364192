import { isPlatformBrowser } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, RouterOutlet } from '@angular/router';
import {
  SidebarComponent,
  NavbarComponent,
  FooterComponent,
  ConfirmationModalComponent,
} from '@app/core/shared/components';
import { COMMON_MODULES } from '@app/core/constants/common-module.constant';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from '@src/app/core/services/shared-data.service';


@Component({
  selector: 'app-layout-base',
  standalone: true,
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  imports: [
    ...COMMON_MODULES,
    RouterOutlet,
    SidebarComponent,
    NavbarComponent,
    ConfirmationModalComponent,
    FooterComponent,
  ],
})
export class BaseComponent {
  public pageSettings: any = {
    link1: {
      title: this.translate.instant('BREADCRUMBS.NETWORK_AND_PLANNING.TITLE'),
    },
  };

  isLoading: boolean | any;
  public isBrowser: boolean = false;
  public isNavLoading: boolean = false;
  private readonly platformId: any;
  currentLang: any;
  /**
   * Constructs a new instance of the BaseComponent class.
   *
   * @param {Injector} injector - The injector used to retrieve the platformId.
   * @param {Router} router - The router service used to subscribe to route events.
   * @param {TranslateService} translate - The translate service used to instant translate strings.
   * @param {SharedDataService} sharedDataService - The shared data service used to subscribe to appConfig$.
   * @param {PageSettingsService} pageSettingsService - The page settings service used to subscribe to pageSettings$.
   */
  constructor(
    injector: Injector,
    private router: Router,
    private translate: TranslateService,
    private sharedDataService: SharedDataService,
    private pageSettingsService: PageSettingsService, // Inject the service
    // private userIdleService: UserIdleService, // Inject the service
  ) {
    this.platformId = injector.get(PLATFORM_ID);
    this.isBrowser = isPlatformBrowser(this.platformId);

    // Spinner for lazyload modules
    router.events.forEach(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });
    this.pageSettingsService.pageSettings$.subscribe(newSettings => {
      if (newSettings) {
        this.pageSettings = newSettings;
      }
    });
    this.sharedDataService.appConfig$.subscribe(res => {
      if (!this.currentLang || this.currentLang !== res.language) {
        this.isNavLoading = true;
        this.currentLang = res.language;
        setTimeout(() => {
          this.isNavLoading = false;
        }, 100);
      }
    });
  }

 
}
